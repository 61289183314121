import React from 'react'
import { Modal } from 'antd'
import errorImg from '../../assets/error-cross.png'

const ErrorModal = ({ isOpen, onClose }) => {
  const handleOk = () => {
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      onCancel={handleOk}
      footer={null}
      header={null}
      closable={false}
    >
      <div className="text-center py-4">
        <img src={errorImg} alt="errorImg" />
        <p className="py-4">
          Oops! Your answer is not correct, please try again.
        </p>
        <button className="error-button" onClick={handleOk}>
          Try Again
        </button>
      </div>
    </Modal>
  )
}
export default ErrorModal
