import Header from '../components/Header'
import parents1 from '../assets/parents1.png'
import parents2 from '../assets/parents2.png'
import parents3 from '../assets/parents3.png'
import parentsImage from '../assets/parentsImage.jpg'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useNavigate } from 'react-router-dom'

const Parents = () => {
  const Navigate = useNavigate()
  return (
    <div>
      <Header />
      <Title text="Parents" />
      <div
        style={{
          textAlign: 'right',
          marginTop: '30px'
        }}
        className="container"
      >
        <button
          onClick={() => Navigate('/bm/parents')}
          style={{ borderRadius: '30px' }}
          className="button"
        >
          Bahasa Melayu
        </button>
      </div>
      <div
        className="container"
        style={{
          marginTop: '20px',
          border: '2px black solid',
          borderRadius: '20px'
        }}
      >
        <div className="parents-container">
          <div className="parents-image">
            <img src={parents1} alt="About Us" />
          </div>
          <div className="parents-text">
            <p
              style={{ color: '#712e88', fontWeight: '600', fontSize: '20px' }}
            >
              Should you be concerned over your child’s growth?
            </p>
            <p style={{ color: 'black' }}>
              It is good for you to want your child to weigh right and be as
              tall as can be. Growth reflects your child’s overall health and
              development, and contributes to better self-esteem and school
              performance.
            </p>
            <div className="button-container">
              <button
                onClick={() => Navigate('/article1')}
                style={{ borderRadius: '30px' }}
                className="button"
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className="parents-container">
          <div className="parents-image">
            <img src={parents2} alt="About Us" />
          </div>
          <div className="parents-text">
            <p
              style={{ color: '#712e88', fontWeight: '600', fontSize: '20px' }}
            >
              So, how well is your child growing?
            </p>
            <p style={{ color: 'black' }}>
              You probably think your child is growing fine. However, at this
              age, it can be difficult to tell whether he/she is growing too
              little, too slowly, or erratically. By using WHO growth charts,
              you can know for sure whether your child is growing normally or
              not.
            </p>
            <div className="button-container">
              <button
                onClick={() => Navigate('/article2')}
                style={{ borderRadius: '30px' }}
                className="button"
              >
                Find out more
              </button>
            </div>
          </div>
        </div>
        <div className="parents-container">
          <div className="parents-image">
            <img src={parents3} alt="About Us" />
          </div>
          <div className="parents-text">
            <p
              style={{ color: '#712e88', fontWeight: '600', fontSize: '20px' }}
            >
              Want to help your child grow optimally?
            </p>
            <p style={{ color: 'black' }}>
              Your doctor can help! By regularly measuring and assessing your
              child’s growth, your doctor will be able to detect any potential
              problems, and provide appropriate advice for your child’s feeding,
              nutrition, physical activity, sleep, and other important areas
              that will help your child achieve his/her growth potential.
            </p>
            <div className="button-container">
              <button
                onClick={() => Navigate('/article3')}
                style={{ borderRadius: '30px' }}
                className="button"
              >
                Questions you can discuss with your doctor
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="two-column-container purple-background left-right-10percent-margin mt-5"
        style={{ alignItems: 'center', borderRadius: '10px' }}
      >
        <div className="text-white">
          <p style={{ textAlign: 'center' }}>
            Not sure where to find a doctor to help your child?
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '30px',
              fontWeight: '700'
            }}
          >
            You may speak to an IMFeD practitioner!
          </p>
          <button
            onClick={() => Navigate('/clinic')}
            style={{
              background: 'transparent',
              color: 'white',
              border: '2px solid white',
              margin: 'auto',
              display: 'block'
            }}
          >
            Find an IMFeD Practitioner
          </button>
        </div>

        <div>
          <img
            className="w-100"
            style={{ borderRadius: '0px 10px 10px 0px' }}
            src={parentsImage}
            alt="About Us"
          />
        </div>
      </div>

      <div
        style={{
          textAlign: 'center',
          marginTop: '50px',
          paddingLeft: '15%',
          paddingRight: '15%'
        }}
      >
        <iframe
          width="915"
          height="415"
          src="https://www.youtube.com/embed/9Y8in8TC5jM?si=Pq2nBRX3ylpc6wfN"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <Footer />
    </div>
  )
}

export default Parents
