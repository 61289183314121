import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import axios from 'axios'

const Contact = () => {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PlaceOfPractice: '',
    Subject: '',
    Message: ''
  })

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/module/contactUs', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      console.log(response)

      console.log(response.data)
      message.success('Message sent')
    } catch (error) {
      console.error('Error submitting form:', error)

      message.error(error.response?.data.error || 'An error occurred')
    }
  }

  return (
    <div>
      <Header />
      <Title
        text="Contact us"
        smallText="If you have any queries, please do not hesitate to drop us a message below"
      />
      <div className="contact-container">
        <div className="contact-form">
          <form>
            <div className="form-group">
              <h6 style={{ marginBottom: '10px' }}>Name</h6>
              <div className="name-fields">
                <div className="name-input-container">
                  <input
                    style={{ border: '1px solid #ccc' }}
                    type="text"
                    id="firstName"
                    className="name-input"
                    value={formData.FirstName}
                    onChange={e => handleChange(e, 'FirstName')}
                  />
                  <label style={{ fontWeight: 'bold' }} htmlFor="firstName">
                    First
                  </label>
                </div>

                <div className="name-input-container">
                  <input
                    style={{ border: '1px solid #ccc' }}
                    type="text"
                    id="lastName"
                    className="name-input"
                    value={formData.LastName}
                    onChange={e => handleChange(e, 'LastName')}
                  />
                  <label style={{ fontWeight: 'bold' }} htmlFor="lastName">
                    Last
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group">
              <h6 style={{ marginBottom: '10px' }}>Email</h6>
              <input
                style={{ border: '1px solid #ccc' }}
                type="email"
                value={formData.Email}
                onChange={e => handleChange(e, 'Email')}
              />
            </div>
            <div className="form-group">
              <h6 style={{ marginBottom: '10px' }}>Place of practice</h6>
              <input
                style={{ border: '1px solid #ccc' }}
                type="text"
                value={formData.PlaceOfPractice}
                onChange={e => handleChange(e, 'PlaceOfPractice')}
              />
            </div>
            <div className="form-group">
              <h6 style={{ marginBottom: '10px' }}>Subject</h6>
              <input
                style={{ border: '1px solid #ccc' }}
                type="text"
                value={formData.Subject}
                onChange={e => handleChange(e, 'Subject')}
              />
            </div>
            <div className="form-group">
              <h6 style={{ marginBottom: '10px' }}>Message</h6>
              <textarea
                value={formData.Message}
                onChange={e => handleChange(e, 'Message')}
                rows={6}
              ></textarea>
            </div>
            <button
              onClick={handleSubmit}
              style={{ background: '#9F9F9F', color: 'black', borderRadius: 0 }}
            >
              Submit
            </button>
          </form>
        </div>
        <div className="google-map">
          <h3 style={{ color: '#712e88' }}>Secretariat Contact Details</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.2182549188567!2d101.63072491444664!3d3.1174905976861044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc48419dbf0429%3A0xe40ab8d4b647b9a4!2sThe%20Suites%20Jaya%20One!5e0!3m2!1sen!2smy!4v1642626761947!5m2!1sen!2smy"
            width="400"
            height="300"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <div className="contact-info">
            <p style={{ fontWeight: '700', textAlign: 'left' }}>
              IMFeD for Growth Secretariat c/o Ultra Works Sdn Bhd
            </p>
            <p style={{ margin: 0 }}>23-5, Block B, The Suites Jaya One,</p>
            <p style={{ margin: 0 }}>72A Jalan Professor Diraja Ungku Aziz,</p>
            <p style={{ margin: 0 }}>46200 Petaling Jaya,</p>
            <p style={{ margin: 0 }}>Selangor, Malaysia</p>
            <p style={{ margin: 0, marginTop: '20px' }}>
              <strong>Email</strong>: secretariat@imfed.my
            </p>
            <p style={{ margin: 0 }}>
              <strong>Tel</strong>: 03-7625 2337 / 03-7625 2342
            </p>
            <p style={{ margin: 0 }}>
              <strong>Whatsapp</strong>: 012-284 1628
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
