import React, { useState } from 'react'
import { Form, Input } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { message } from 'antd'

// Configure message position
message.config({
  top: '20%' // Set the message box at 20% from the top
})

const AskExpert = () => {
  const location = useLocation()
  const userId = useSelector(state => state.user.user?.data.id)

  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    userId: userId,
    moduleId: location.state
  })

  const handleChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value
    })
  }

  const handleSubmit = async () => {
    try {
      await axios.post('/api/module/askExpert', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      message.success({
        content:
          'Your question has been posted. The question will be reflected after the experts have answered your questions.',
        duration: 5, // Message disappears after 5 seconds
        className: 'custom-success-message' // Custom class for styling
      })
    } catch (error) {
      console.error('Error submitting form:', error)
      message.error(error.response?.data.error || 'An error occurred')
    }
  }

  return (
    <div>
      <Header />
      <Title text="Paediatric Growth & Nutrition Course" />

      <div
        className="container"
        style={{
          background: 'linear-gradient(180deg, #FBEFFF 0%, #FBEFFF 100%)',
          borderRadius: '10px',
          padding: '50px'
        }}
      >
        <h4 style={{ textAlign: 'center' }}>Submit your question</h4>
        <Form name="askExpertForm" className="mt-5" onFinish={handleSubmit}>
          <div>Subject</div>
          <Input
            className="input-expert"
            value={formData.subject}
            onChange={e => handleChange(e, 'subject')}
            required={true}
          />

          <div className="mt-4">Description</div>
          <Input.TextArea
            className="input-expert"
            rows={10}
            value={formData.description}
            onChange={e => handleChange(e, 'description')}
            required={true}
          />

          <div className="mt-4 d-flex justify-content-between">
            <button
              className="sm-rounded-button"
              style={{ padding: '10px 30px' }}
              onClick={() => window.history.back()}
            >
              Back
            </button>
            <button
              className="sm-rounded-button"
              style={{ padding: '10px 30px' }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form>
      </div>
      <Footer />
    </div>
  )
}

export default AskExpert
