import Header from '../components/Header'
import parents1 from '../assets/parents1.png'
import parents2 from '../assets/parents2.png'
import parents3 from '../assets/parents3.png'
import parentsImage from '../assets/parentsImage.jpg'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useNavigate } from 'react-router-dom'

const Parents = () => {
  const Navigate = useNavigate()
  return (
    <div>
      <Header />
      <Title text="Panduan Ibu Bapa" />
      <div
        style={{
          textAlign: 'right',
          marginTop: '30px'
        }}
        className="container"
      >
        <button
          onClick={() => Navigate('/parents')}
          style={{ borderRadius: '30px' }}
          className="button"
        >
          English
        </button>
      </div>
      <div
        className="container"
        style={{
          marginTop: '20px',
          border: '2px black solid',
          borderRadius: '20px'
        }}
      >
        <div className="parents-container">
          <div className="parents-image">
            <img src={parents1} alt="About Us" />
          </div>
          <div className="parents-text">
            <p
              style={{ color: '#712e88', fontWeight: '600', fontSize: '20px' }}
            >
              Perlukah anda bimbang akan tumbesaran anak anda?
            </p>
            <p style={{ color: 'black' }}>
              Bagus sekiranya anda mahukan anak anda mempunyai berat dan
              ketinggian yang ideal. Tumbesaran mencerminkan kesihatan dan
              perkembangan keseluruhan anak anda, dan menyumbang kepada
              keyakinan diri dan pencapaian sekolah yang lebih baik.
            </p>
            <div className="button-container">
              <button
                onClick={() => Navigate('/bm/article1')}
                style={{ borderRadius: '30px' }}
                className="button"
              >
                Ketahui lanjut
              </button>
            </div>
          </div>
        </div>
        <div className="parents-container">
          <div className="parents-image">
            <img src={parents2} alt="About Us" />
          </div>
          <div className="parents-text">
            <p
              style={{ color: '#712e88', fontWeight: '600', fontSize: '20px' }}
            >
              Jadi, adakah anak anda membesar dengan baik?
            </p>
            <p style={{ color: 'black' }}>
              Anda mungkin merasakan anak anda membesar dengan baik. Namun, pada
              usia begini, sukar untuk mengetahui sama ada tumbesaran anak anda
              berlaku dengan terlalu sedikit, terlalu perlahan atau tidak
              menentu. Dengan menggunakan carta tumbesaran WHO, anda dapat
              mengetahui sama ada tumbesaran anak anda adalah normal atau tidak.
            </p>
            <div className="button-container">
              <button
                onClick={() => Navigate('/bm/article2')}
                style={{ borderRadius: '30px' }}
                className="button"
              >
                Baca lebih lanjut
              </button>
            </div>
          </div>
        </div>
        <div className="parents-container">
          <div className="parents-image">
            <img src={parents3} alt="About Us" />
          </div>
          <div className="parents-text">
            <p
              style={{ color: '#712e88', fontWeight: '600', fontSize: '20px' }}
            >
              Adakah anda mahu membantu anak membesar dengan baik?
            </p>
            <p style={{ color: 'black' }}>
              Doktor anda boleh bantu! Dengan mengukur dan menilai tumbesaran
              anak anda secara berkala, doktor anda dapat mengesan sebarang
              kemungkinan masalah, dan memberikan nasihat yang sewajarnya dalam
              pemberian makanan, pemakanan, aktiviti fizikal, tidur dan perkara
              penting lain yang akan membantu anak anda mencapai potensi
              tumbesaran mereka.
            </p>
            <div className="button-container">
              <button
                onClick={() => Navigate('/bm/article3')}
                style={{ borderRadius: '30px' }}
                className="button"
              >
                Soalan-soalan yang anda boleh bincangkan dengan doktor anda
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="two-column-container purple-background left-right-10percent-margin mt-5"
        style={{ alignItems: 'center', borderRadius: '10px' }}
      >
        <div className="text-white">
          <p style={{ textAlign: 'center' }}>
            Tidak pasti di mana untuk berjumpa doktor yang boleh membantu anak
            anda?
          </p>
          <p
            style={{
              textAlign: 'center',
              fontSize: '30px',
              fontWeight: '600'
            }}
          >
            Anda mungkin boleh mendapatkan khidmat nasihat pengamal IMFeD!
          </p>
          <button
            onClick={() => Navigate('/clinic')}
            style={{
              background: 'transparent',
              color: 'white',
              border: '2px solid white',
              margin: 'auto',
              display: 'block'
            }}
          >
            Cari pakar IMFeD
          </button>
        </div>

        <div>
          <img
            className="w-100"
            style={{ borderRadius: '0px 10px 10px 0px' }}
            src={parentsImage}
            alt="About Us"
          />
        </div>
      </div>

      <div
        style={{
          textAlign: 'center',
          marginTop: '50px',
          paddingLeft: '15%',
          paddingRight: '15%'
        }}
      >
        <iframe
          width="915"
          height="415"
          src="https://www.youtube.com/embed/9Y8in8TC5jM?si=Pq2nBRX3ylpc6wfN"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <Footer />
    </div>
  )
}

export default Parents
