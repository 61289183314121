import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import ModuleVideo from '../components/ModuleVideo'
import ModuleAssessment from '../components/ModuleAssessment'
import ModuleCertificate from '../components/ModuleCertificate'
import ModulePreAssessment from '../components/ModulePreAssessment'
import { useSelector } from 'react-redux'

const ModuleContent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedSidebarItem, setSelectedSidebarItem] = useState(
    'PreAssessment'
  )
  const [module, setModule] = useState('')
  const [certificate, setCertificate] = useState('')
  const [video, setVideo] = useState('')
  const [preAssessment, setPreAssessment] = useState('')
  const userId = useSelector(state => state.user.user?.data.id)

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const response = await axios.post('/api/module/getVideo', {
          id: location.state
        })

        const data = response.data

        if (response.status === 200) {
          setModule(data.data)
        } else {
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    if (location.state) {
      fetchModuleData()
    }
  }, [location.state])

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.post('/api/module/getModuleVideo', {
          ModuleId: location.state,
          UserId: userId
        })

        const data = response.data

        if (response.status === 200) {
          setVideo(data.video)
        } else {
          setVideo('')
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    if (location.state && userId) {
      fetchVideoData()
    }
  }, [location.state, userId])

  useEffect(() => {
    const fetchPreAssessmentStatus = async () => {
      try {
        const response = await axios.post(
          '/api/module/getPreAssessmentStatus',
          {
            ModuleId: location.state,
            UserId: userId
          }
        )

        const data = response.data
        if (response.status === 200) {
          setPreAssessment(data.preAssessment)
        } else {
          setPreAssessment('')
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        console.error('Error fetching module data:', error)
      }
    }

    if (location.state && userId) {
      fetchPreAssessmentStatus()
    }
  }, [location.state, userId])

  useEffect(() => {
    const fetchCertificateData = async () => {
      try {
        const response = await axios.post('/api/module/getCertificate', {
          ModuleId: location.state,
          UserId: userId
        })

        const data = response.data
        // console.log('data', data)
        if (data.certificate) {
          setCertificate(data.certificate)
        } else {
          setCertificate('')
          console.error('Failed to fetch module data')
        }
      } catch (error) {
        if (error.response?.data.error === 'Invalid Credentials') {
          navigate('/login')
        } else {
          message.error(error.response?.data.error)
        }
      }
    }

    if (location.state && userId && preAssessment && video) {
      fetchCertificateData()
    }
  }, [preAssessment, video, location.state, userId, navigate])
  // console.log('video', video)

  useEffect(() => {
    if (certificate === true) {
      setSelectedSidebarItem('Certificate')
    } else {
      if (preAssessment === true && video === false) {
        setSelectedSidebarItem('Video')
      } else if (preAssessment === true && video === true) {
        setSelectedSidebarItem('Assessment')
      } else if (
        preAssessment === false &&
        video === false &&
        certificate === false
      ) {
        setSelectedSidebarItem('PreAssessment')
      }
    }
  }, [preAssessment, video, certificate])

  // console.log('video', video)

  const renderSidebarStyling = (selectedSidebarItem, item) => {
    return {
      borderLeft: selectedSidebarItem === item ? '10px solid #fff' : 'none',
      fontWeight: selectedSidebarItem === item ? 'bold' : 'normal',
      paddingLeft: '3px',
      marginLeft: selectedSidebarItem === item ? '-10px' : '0px',
      cursor: 'pointer',
      marginTop: '25px'
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Header />
      <Title text="Paediatric Growth & Nutrition Course" />

      <div className="container">
        {selectedSidebarItem !== 'Certificate' ? (
          <div className="button-bar">
            <button
              className="sm-rounded-button"
              style={{ padding: '10px 30px' }}
              onClick={() => navigate('/module')}
              htmlType="submit"
            >
              Previous
            </button>
            <h5 style={{ margin: 'auto' }}>{module.title}</h5>
          </div>
        ) : (
          <div className="button-bar">
            <button
              style={{ display: 'none', padding: '10px 30px' }}
              onClick={() => navigate('/module')}
              htmlType="submit"
              className="sm-rounded-button"
            >
              Previous
            </button>
            <h4 style={{ margin: 'auto' }}>Certificate</h4>
          </div>
        )}
        <div className="d-flex">
          <div className="sidebar">
            <ul className="no-bullets">
              {preAssessment ? (
                <div>
                  <li
                    style={renderSidebarStyling(
                      selectedSidebarItem,
                      'PreAssessment'
                    )}
                  >
                    Pre Assessment
                  </li>
                  <li
                    style={renderSidebarStyling(selectedSidebarItem, 'Video')}
                    onClick={() => setSelectedSidebarItem('Video')}
                  >
                    Video
                  </li>
                  <li
                    style={renderSidebarStyling(
                      selectedSidebarItem,
                      'Assessment'
                    )}
                    onClick={() =>
                      video ? setSelectedSidebarItem('Assessment') : null
                    }
                  >
                    Assessment
                  </li>
                </div>
              ) : (
                <div>
                  <li
                    style={renderSidebarStyling(
                      selectedSidebarItem,
                      'PreAssessment'
                    )}
                    onClick={() => setSelectedSidebarItem('PreAssessment')}
                  >
                    Pre Assessment
                  </li>
                  <li
                    style={renderSidebarStyling(selectedSidebarItem, 'Video')}
                  >
                    Video
                  </li>
                  <li
                    style={renderSidebarStyling(
                      selectedSidebarItem,
                      'Assessment'
                    )}
                  >
                    Assessment
                  </li>
                </div>
              )}
              {certificate ? (
                <li
                  style={renderSidebarStyling(
                    selectedSidebarItem,
                    'Certificate'
                  )}
                  onClick={() => setSelectedSidebarItem('Certificate')}
                >
                  Certificate
                </li>
              ) : (
                <li
                  style={renderSidebarStyling(
                    selectedSidebarItem,
                    'Certificate'
                  )}
                >
                  Certificate
                </li>
              )}

              <li
                className="mt-3 cursor-pointer"
                onClick={() =>
                  navigate('/askExpert', { state: location.state })
                }
              >
                Ask the Expert
              </li>
            </ul>
          </div>
          <div className="w-100">
            {(() => {
              switch (selectedSidebarItem) {
                case 'Video':
                  return (
                    <ModuleVideo
                      setSelectedSidebarItem={setSelectedSidebarItem}
                    />
                  )
                case 'Assessment':
                  return <ModuleAssessment navigate={navigate} />
                case 'Certificate':
                  return <ModuleCertificate />
                case 'PreAssessment':
                  return <ModulePreAssessment />
                default:
                  return null // Render nothing if no match
              }
            })()}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ModuleContent
